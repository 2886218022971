<script setup lang="ts">
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { computed, shallowRef, watchEffect } from 'vue';

interface Props {
  name: string | string[];
  size?: string | number;
  interactive?: boolean;
  rotate?: number | string;
  spin?: boolean;
}

const props = defineProps<Props>();

const icon = shallowRef();

const classes = computed(() => [
  'ui-icon',
  {
    'ui-icon--interactive': props.interactive,
    'ui-icon--spin': props.spin,
  },
]);

const isFaIcon = computed(() => {
  const prefix = Array.isArray(props.name) ? props.name[0] : 'fas';
  return findIconDefinition({ iconName: props.name as IconName, prefix: prefix as IconPrefix });
});

watchEffect(() => {
  if (isFaIcon.value) return;

  import(`@/assets/ui-icons/${props.name}.svg`).then(
    (value) => (icon.value = value.default),
  );
},
);
</script>

<template>
  <span
    :class="classes"
    :style="{
      transform: rotate ? `rotate(${rotate}deg)` : undefined,
      fontSize: size ? `${size}px` : undefined,
    }"
  >
    <FaIcon
      v-if="isFaIcon"
      :icon="name"
      :role="interactive ? 'button' : null"
      :tabindex="interactive ? 0 : null"
    />
    <component
      :is="icon"
      v-else
      :role="interactive ? 'button' : null"
      :tabindex="interactive ? 0 : null"
    />
  </span>
</template>

<style lang="scss">
.ui-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &--interactive {
    cursor: pointer;
  }

  &--spin {
    animation: spin 1s infinite linear;
  }

  svg {
    fill: none;
    width: 1em;
    height: 1em;
  }
}
</style>
