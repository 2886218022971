<script setup lang="ts">
interface Props {
  maxWidth?: string;
  hideIcon?: boolean;
}

defineProps<Props>();
</script>

<template>
  <d-tooltip
    class="popper-info"
    placement="bottom start"
    variant="secondary"
    :max-width="maxWidth"
    open-on-click
  >
    <d-icon
      class="popper-info__activator"
      path="/assets/icons/question-circle.svg"
      size="20"
    />

    <template #content>
      <div class="popper-info__content">
        <div v-if="!hideIcon">
          <d-icon
            path="/assets/icons/question-circle.svg"
            size="16"
          />
        </div>

        <p>
          <slot />
        </p>
      </div>
    </template>
  </d-tooltip>
</template>

<style lang="scss">
.popper-info {
  &__content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__activator {
    opacity: 0.5;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
