<script setup lang="ts">
interface Props {
  text?: string;
  tag?: string;
  weight?: 'regular' | 'medium' | 'bold';
  variant?: 'default' | 'secondary' | 'info' | 'error';
}

withDefaults(defineProps<Props>(), {
  tag: 'div',
  weight: 'regular',
  variant: 'default',
  text: undefined,
});
</script>

<template>
  <component
    :is="tag"
    :class="`ui-caption ui-caption--${weight} ui-caption--${variant}`"
  >
    <span class="ui-caption__content">
      <slot>{{ text }}</slot>
    </span>
  </component>
</template>

<style lang="scss">
.ui-caption {
  font-size: 14px;
  line-height: 140%;

  &--regular {
    font-weight: 400;
    line-height: 140%;
  }

  &--medium {
    font-weight: 500;
    line-height: 120%;
  }

  &--bold {
    font-weight: 700;
    line-height: 100%;
  }

  &__content {
    display: inline-block;
  }

  &--default &__content {
    color: $light-gray;
  }

  &--secondary &__content {
    color: $dark-gray;
  }

  &--error &__content {
    color: $desktop-red;
  }

  &--info &__content {
    color: $desktop-main-blue;
  }
}
</style>
