<script lang="ts" setup>
import { computed, useId } from 'vue';
import { UiLabel } from './v2';

export interface SelectOption {
  title: string;
  value: string;
}

const { label = '', maxWidth = 'unset', multiple = false, variant = 'select', color = '#18191D' } = defineProps<{
  label?: string;
  maxWidth?: string | number;
  multiple?: boolean;
  variant?: 'select' | 'combobox' | 'autocomplete';
  color?: string;
}>();

const slots = defineSlots<{
  popper?: () => unknown;
}>();

const id = computed(() => {
  return `select-${useId()}`;
});

const component = computed(() => `v-${variant}`);
</script>

<template>
  <div
    class="select"
    :class="[`select--${variant}`]"
    :style="{ maxWidth }"
  >
    <UiLabel
      v-if="label"
      class="select__label"
      :for="id"
      :text="label"
    >
      <template
        v-if="slots.popper"
        #popper
      >
        <slot name="popper" />
      </template>
    </UiLabel>

    <component
      v-bind="$attrs"
      :is="component"
      :id="id"
      :menu-props="{ maxWidth: '100%' }"
      :list-props="{ 'bg-color': color }"
      :multiple="multiple"
      :persistent-placeholder="variant === 'combobox'"
      menu-icon="fas fa-angle-down"
      :bg-color="color"
      :base-color="color"
    >
      <template
        v-if="multiple"
        #selection="{ item }"
      >
        <VChip
          class="!h-4 !px-2 text-[#1871F8] !text-[10px] border-[#1871F8]"
          variant="outlined"
        >
          <span>{{ item.title }}</span>
        </VChip>
      </template>
    </component>
  </div>
</template>
